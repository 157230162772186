<template lang="pug">
v-dialog(v-model='dialogVisible' scrollable persistent :fullscreen='$vuetify.breakpoint.xs' :hide-overlay='$vuetify.breakpoint.xs' max-width="1200px" :retain-focus="false" @click:outside="cancel")
  template(v-slot:activator='{ on, attrs }')
    v-btn.ml-auto(icon v-bind='attrs' v-on='on')
      v-icon(color='accent' size='22px') mdi-pencil
  v-form( ref="conditionsForm" lazy-validation :key="formKey" v-model="valid")
    v-card.d-flex.flex-column(min-height="70vh")
      div
        v-toolbar(flat color="transparent")
          v-toolbar-title.text-h6.dialog-title.font-weight-bold
            | Gewünschte Tätigkeit bearbeiten
          v-spacer
          v-btn(icon @click='dialogVisible=false')
            v-icon mdi-close
        v-divider
      v-card-text.flex-grow-1.px-4.pt-6
        UpdateConditionsForm.flex-grow-1(v-model="conditionsItem")
      v-divider
      v-card-actions.px-4
          v-spacer
          v-btn(color='grey' text @click='cancel')
            | Abbrechen
          v-btn.white--text(color='accent darken-1' depressed @click='setConditions' :disabled="!valid")
            | Speichern
</template>

<script>
import Vue from 'vue';
import { formatDateMonth } from '@/utilities/DateAndTime';
import { formatCurrency } from '@/utilities/Formats';
import InputRow from '@/components/inputs/InputRow.vue';
import SelectAreaMenu from '@/components/dialogs/SelectAreaDialog.vue';
import LocationSelector from '@/components/inputs/locationSelector.vue';
import { countries } from '@/assets/selections/countries';
import jobTypes from '@/assets/selections/jobTypes.json';
import benefits from '@/assets/selections/benefits.json';
import experienceLevels from '@/assets/selections/experienceLevels.json';
import workingTimes from '@/assets/selections/workingTimes.json';
import workingDurations from '@/assets/selections/workingDurations.json';
import { cloneDeep } from 'lodash';
import UpdateConditionsForm from './UpdateConditionsForm.vue';
export default Vue.extend({
  components: {
    SelectAreaMenu,
    InputRow,
    LocationSelector,
    UpdateConditionsForm,
  },
  props: ['conditions', 'index'],
  data() {
    const vacationDaysTextField = this.conditions?.vacationDays ?? '-';
    return {
      valid: false,
      formKey: Math.random(),
      experienceLevels: experienceLevels.lookingFor,
      dialogVisible: false,
      workingTimeMenu: false,
      jobTypeList: jobTypes.offering,
      workingTimeList: workingTimes,
      durationList: workingDurations,
      nationalities: countries,
      vacationDaysList: [],
      benefitsList: benefits,
      vacationDaysTextField,

      conditionsItem: {
        jobType: [],
        startDate: '',
        workingTime: [],
        duration: [],
        nationality: '',
        city: '',
        level: [],
        desiredSalary: 0,
        vacationDays: 0,
        benefits: [],
        areas: [],
      },
      // currentDate: new Date().toISOString().slice(0, 10),
    };
  },
  computed: {
    // conditionsItem() {
    //   return this.conditions
    //     ? cloneDeep(this.conditions)
    //     : {
    //         jobType: [],
    //         startDate: '',
    //         workingTime: [],
    //         duration: [],
    //         nationality: '',
    //         city: '',
    //         level: [],
    //         desiredSalary: 0,
    //         vacationDays: 0,
    //         benefits: [],
    //         areas: [],
    //       };
    // },
    currentDate() {
      return new Date().toISOString();
    },
    vacationDaysTextValue() {
      if (!this.conditionsItem.vacationDays) {
        return '-';
      } else {
        return this.conditionsItem.vacationDays;
      }
    },
  },
  created() {
    if (this.conditions) {
      this.conditionsItem = cloneDeep(this.conditions);
    }
    const vacationList = [];
    for (let i = 20; i <= 30; i++) {
      vacationList.push({ id: i, name: `${i} Tage` });
    }
    this.vacationDaysList = vacationList;
  },
  methods: {
    cancel() {
      this.conditionsItem = cloneDeep(this.conditions);
      this.close();
    },
    close() {
      this.dialogVisible = false;
      this.formKey++;
    },
    setConditions() {
      if (!this.$refs.conditionsForm.validate()) {
        return;
      }

      if (this.conditionsItem.vacationDays < 4) {
        this.conditionsItem.vacationDays = undefined;
      }
      this.$store
        .dispatch('candidate/partialUpdate', {
          item: this.conditionsItem,
          key: 'conditions',
        })
        .then(() => {
          this.close();
        });
    },
    areaItemsChanged(areas) {
      this.conditionsItem.areas = areas;
    },
    vacationDaysChanges(days) {
      this.vacationDaysTextField = days > 3 ? days : '-';
    },
    formatCurrency,
    formatDateMonth,
  },
});
</script>

<style lang="sass">


@import "@/assets/style/main"


// .gray-caption
//   @include uppercase-gray-bold-text(12px)

// .status-chip-select
//   background-color: inherit !important
//   color: gray !important
//   border: solid gray 2px !important
//   border-radius: 7px !important
//   padding: 0px 10px 0px 10px !important
//   font-size: 10px !important
//   height: 20px !important
//   font-weight: 700 !important
//   text-transform: uppercase

// .status-chip-selected
//   color: white !important
//   background-color: gray !important
</style>
