
import UpdateExperienceDialog from './UpdateExperienceDialog.vue';
import Row from '@/components/cards/CardRow.vue';
import {
  Experience,
  showAttribute,
  getLabelForTitle,
  ExperienceType,
} from '@/types/experience';
import Vue, { PropType } from 'vue';
import { CardRowType } from '@/types/shared';
import { notEmpty } from '@/utilities/Utils';

export default Vue.extend({
  data() {
    return {
      CardRowType,
    };
  },
  props: {
    experienceItems: { type: Array as PropType<Experience[]> },
    index: { type: Number },
    type: { type: String },
    sectionTitle: { type: String },
    isAddDialog: { type: Boolean },
  },
  computed: {
    selectedAreas() {
      const areaCategories =
        this.experienceItems[this.index].areas
          ?.map((area) => area.areaCategory)
          .filter(notEmpty)
          .map((category) => category.name) ?? [];

      const areas =
        this.experienceItems[this.index].areas?.map((a) => a.name) ?? [];
      return [...[...new Set(areaCategories)], ...areas];
    },
    experienceItem(): Experience {
      return this.experienceItems[this.index];
    },
    duration(): string {
      return (this as any).experienceItem.durationYears > 0
        ? `${(this as any).experienceItem.durationYears} ${
            (this as any).experienceItem.durationYears === 1 ? 'Jahr' : 'Jahre'
          }`
        : '-';
    },
    titleLabel(): string {
      return getLabelForTitle(this.experienceItems[this.index]);
    },
    cardTitle(): string {
      switch (this.type) {
        case ExperienceType.PRACTICAL_EXPERIENCE:
          return 'Praxiserfahrung';
        case ExperienceType.OTHER_EXPERIENCE:
          return 'Auslandserfahrung';
        default:
          return '';
      }
    },
  },
  methods: {
    isVisible(row: string): boolean {
      return showAttribute(this.experienceItems[this.index], row, false, true);
    },
  },
  components: {
    Row,
    UpdateExperienceDialog,
  },
});
