<template lang="pug">
.conditions
    .elements(v-if="!showOnboardingMessage")
        cardConditions(:item="conditions" title="Gewünschte Tätigkeit" userType="candidate")
    StartOnboardingCard(v-else v-on="$listeners" )
</template>

<script>
import CardConditions from '@/components/candidate/lookingFor/conditions/CardConditions';
import StartOnboardingCard from '@/components/cards/StartOnboardingCard';

export default {
  components: {
    CardConditions,
    StartOnboardingCard,
  },
  data() {
    return {
      updateProfileDialog: true,
      leftColumnWidth: 3,
    };
  },
  computed: {
    conditions() {
      return this.$store.state.candidate.candidate.conditions ?? {};
    },
    showOnboardingMessage() {
      return this.$store.getters['user/showOnboardingMessage'];
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.$store.dispatch('candidate/fetchCurrentCandidate');
  },
  methods: {},
};
</script>

<style lang="sass">
@import '@/assets/style/main'
.conditions
  margin-bottom: 60px
.margin-top
  margin-top: 20px

.right-col
  padding-left: 20px
</style>
