<template lang="pug">
CardMobileDesktop(:isLoading="loading" :title="title")
  UpdateExperienceDialog(slot="actionSlot" :experienceItems="experienceItems" :type='type' :indexToUpdate="0" :sectionTitle="title" :isAddDialog="experienceItems.length === 0" v-if="!disableEdit")
  div.py-2.pt-3(slot="inputSlot")
    EmptyCardText(v-if='!experienceItems ||experienceItems.length === 0')
    div(v-else v-for='(entry, index) in experienceItems' :key="index")
      CardExperienceItem(:experienceItems="experienceItems"  :type='type' :index="index" :sectionTitle="title" :isAddDialog='false' :disableEdit="disableEdit")
</template>

<script>
import CardExperienceItem from './CardExperienceItem';
import UpdateExperienceDialog from './UpdateExperienceDialog';
import CardMobileDesktop from '@/components/cards/CardMobileDesktop';
import EmptyCardText from '@/components/cards/EmptyCardText';

export default {
  components: {
    CardExperienceItem,
    UpdateExperienceDialog,
    CardMobileDesktop,
    EmptyCardText,
  },
  props: ['experienceItems', 'type', 'loading', 'title', 'disableEdit'],
  computed: {
    practicalExperienceTimeSpan() {
      return `${this.experienceData.timeStart} - ${this.experienceData.timeEnd}`;
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/style/main'



// .status-chip
//   background-color: gray
//   color: white
//   border-radius: 7px
//   padding: 2px 10px 2px 10px
//   text-transform: uppercase
//   font-size: 10px
//   font-weight: 800
.color-gray
  color: rgba(0, 0, 0, 0.6)
</style>
