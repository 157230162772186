<template lang="pug">
.cardMobileDesktop
  v-skeleton-loader.v-card.margin-top(v-if="isLoading" class="mx-auto" height="116" type="table-heading, list-item-two-line")
  v-card.margin-top(v-else min-height="116")
    //- Mobile UI
    v-toolbar.hidden-md-and-up(flat style="border-bottom: 1.2px solid #c4c4c4; padding-right: 5px")
      span.subcaption {{title}}
      v-spacer.hidden-md-and-up(min-height="116")
      slot(name="actionSlot")
    //- v-divider(style="").hidden-md-and-up
    v-card-text.hidden-md-and-up
      slot(name="inputSlot")
    
    //- Desktop UI
    
    v-row.margin-sides.hidden-sm-and-down(style="min-height: 116px")
      v-col.hidden-sm-and-down.left-col(:cols='3' min-height="116")
        .d-flex.mt-2.justify-space-between.full-width
          span.subcaption.mr-2 {{title}}
          slot(name="actionSlot")
      v-divider(vertical)
      v-col.right-col(min-height="116")
        slot(name="inputSlot")
    
</template>
<script>
export default {
  props: ['title', 'isLoading'],
};
</script>
