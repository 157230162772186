import { render, staticRenderFns } from "./CardEducationItem.vue?vue&type=template&id=36f1a25e&lang=pug&"
import script from "./CardEducationItem.vue?vue&type=script&lang=ts&"
export * from "./CardEducationItem.vue?vue&type=script&lang=ts&"
import style0 from "./CardEducationItem.vue?vue&type=style&index=0&id=36f1a25e&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports