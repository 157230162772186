<template lang="pug">
CardMobileDesktop(:isLoading="isLoading" :title="title")
  UpdateConditionsDialog(slot="actionSlot" :conditions="item" v-if="!disableEdit")
  div(slot="inputSlot")
    div.py-4(v-if="item")
      Row(title='Anstellungsart', :value='item.jobType'  :border="true" :type="CardRowType.CHIPS")
      Row(title='Beginn', :value='item.startDate'  :border="true"  :type="CardRowType.MONTH")
      Row(title='Arbeitszeit', :value='item.workingTime'  :border="true"  :type="CardRowType.CHIPS")
      //- Row(title='Land', :value='item.nationality ? item.nationality.name : ""'  :border="true" :type="CardRowType.TEXT")
      Row(title='Laufzeit', :value='item.duration'  :border="true"  :type="CardRowType.CHIPS")
      Row(title='Ort', :value='item.city && item.city.name' :type="CardRowType.TEXT"  :border="true")
      Row(title='Bereich', :value='selectedAreas'  :border="true"  :type="CardRowType.CHIPS")
      Row(title='Level', :value='item.level' :type="CardRowType.CHIPS"  :border="true")
      Row(title='Jahresgehalt', :value='formatCurrency(item.desiredSalary)' :type="CardRowType.TEXT"  :border="true")
      Row(title='Jahresurlaub', :value='formatVacationDays(item.vacationDays)' :type="CardRowType.TEXT"  :border="true")
      Row(title='Benefits', :value='item.benefits'  :type="CardRowType.CHIPS")
    EmptyCardText.py-4(v-else)
    
</template>

<script>
import Row from '@/components/cards/CardRow';
import { CardRowType } from '@/types/shared';
import { formatDate, formatVacationDays } from '@/utilities/DateAndTime';
import { formatCurrency } from '@/utilities/Formats';
import EmptyCardText from '@/components/cards/EmptyCardText';
import UpdateConditionsDialog from './UpdateConditionsDialog';
import CardMobileDesktop from '@/components/cards/CardMobileDesktop';
import { notEmpty } from '@/utilities/Utils';

export default {
  components: {
    UpdateConditionsDialog,
    Row,
    CardMobileDesktop,
    EmptyCardText,
  },
  props: ['title', 'item', 'disableEdit'],
  data() {
    return {
      CardRowType,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.candidate.loading;
    },
    selectedAreas() {
      const areaCategories =
        this.item.areas
          ?.map((area) => area.areaCategory)
          .filter(notEmpty)
          .map((category) => category.name) ?? [];

      const areas = this.item.areas?.map((a) => a.name) ?? [];
      return [...[...new Set(areaCategories)], ...areas];
    },
  },
  methods: {
    formatDate,
    formatCurrency,
    formatVacationDays,
  },
};
</script>

<style lang="sass">
@import '@/assets/style/main'
.divider
  margin: 15px 0 25px 0
</style>
