<template lang="pug">
div
  v-row.align-center
    v-col.text-truncate.text-no-wrap(cols='6', md='4')
      v-icon.mr-2.iconPosition(v-if="icon" v-html='icon', small)
      span.tag-title.ml-0 {{title}}
      v-divider.hidden-sm-and-down(vertical)
    v-col(cols='6', md='8')
      span.flag-icon.mr-2(
        v-if="flag"
        :class='`flag-icon-${flag}`'
        )
      span.text-black(v-if="showText") {{getValue}}
      div(v-if="type === CardRowType.LIST && value !== null")
        v-chip.status-chip.status-chip-select.mr-2.mb-1.mb-md-0( v-for="(item, i) in value" :key="i" small label) {{item}}
      div.d-flex.flex-column(v-if="type === CardRowType.FLAGS && value !== null")
        span.mb-0(v-if="value.length === 0") -
        div.mb-1(v-for="(item, i) in value" :key="i" v-else)
          span.flag-icon.mr-2( :class='`flag-icon-${item.alpha2}`')
          span.ml-0.text-black {{item.name}}
      div(v-if="type === CardRowType.STATUS && value !== null")
        v-chip.status-chip.status-chip-select.mr-2.mb-1.mb-md-0.status-text( v-for="(item, i) in value" :key="i" small label) {{item}}
      div(v-if="type === CardRowType.CHIPS && value !== null")
        span(v-if="!value || value.length == 0") -
        .customChip( v-for="(item, i) in value" :key="i" ) 
          span.text-truncate {{item}}
        //- v-chip.chips.mr-2.my-1.mb-md-0(v-for="(item, i) in value" :key="i" color="primary" small label) {{item}}
      v-chip.pl-4(v-if="type === CardRowType.GENDER && value !== Gender.UNSPECIFIED" color="primary lighten-4" small label) 
        v-icon.mr-2(left small) {{`mdi-gender-${getGenderTranslation(value)}`}}
        | {{value}}
      v-avatar.profile-picture(size='110' v-if="type === CardRowType.AVATAR || type === CardRowType.LOGO")
        Avatar(:src="value" :business="type === CardRowType.LOGO")
        //- v-img.grey.lighten-4(contain :src='value' )
        //-   template(v-slot:placeholder)
        //-     v-row.fill-height.ma-0(align='center', justify='center')
        //-       v-icon(v-if="type === CardRowType.AVATAR" color='white', size='60') mdi-account-circle
      img.imageSize(:src='value' v-if="type === CardRowType.IMAGE")
  v-divider.my-3(v-if="border !== undefined && border != false")
</template>

<script>
import { CardRowType } from '@/types/shared';
import Avatar from '@/components/elements/Avatar.vue';
import {
  formatDate,
  yearFromDate,
  calculateAge,
  formatDateMonth,
} from '@/utilities/DateAndTime';
import { Gender } from '@/types/user';
export default {
  components: { Avatar },
  props: ['title', 'value', 'icon', 'type', 'border', 'flag'],
  data() {
    return {
      CardRowType,
      Gender,
    };
  },
  computed: {
    getValue() {
      const value = this.value;
      if (this.type === CardRowType.BOOLEAN) {
        return value ? 'Ja' : '-';
      } else if (!value || value === '') {
        return '-';
      } else if (this.type === CardRowType.DATE) {
        return `${formatDate(value)} (${calculateAge(value)})`;
      } else if (this.type === CardRowType.MONTH) {
        return formatDateMonth(value);
      } else if (this.type === CardRowType.YEAR) {
        return yearFromDate(value);
      } else if (this.type === CardRowType.DECIMAL) {
        if (!value) return '-';
        return value.toFixed(1).replace(/\./g, ',');
      } else {
        return value;
      }
    },
    showText() {
      const show =
        (this.type === CardRowType.GENDER &&
          this.value === Gender.UNSPECIFIED) ||
        this.type === CardRowType.TEXT ||
        this.type === CardRowType.DECIMAL ||
        this.type === CardRowType.DATE ||
        this.type === CardRowType.YEAR ||
        this.type === CardRowType.MONTH ||
        this.type === CardRowType.BOOLEAN;

      return show;
    },
  },
  methods: {
    getGenderTranslation(value) {
      switch (value) {
        case Gender.MALE:
          return 'male';
        case Gender.FEMALE:
          return 'female';
        case Gender.OTHER:
          return 'transgender';
        default:
          return '-';
      }
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/style/main'

.text-black
  color: #000 !important
  opacity: 1
.iconPosition
  margin-bottom: 2px !important
.imageSize
  max-height: 60px
  max-width: 300px
.status-text
  .v-chip__content
    padding-top: 2px !important
.customChip
  background-color: #919191
  padding: 3px 12px
  color: #fff
  font-size: 12px
  margin-bottom: 3px
  margin-top: 3px
  margin-right: 6px
  display: inline-flex
  border-radius: 4px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  max-width: 100%
</style>
