
import UpdateEducationDialog from './UpdateEducationDialog.vue';
import Row from '@/components/cards/CardRow.vue';
import {
  Education,
  getLabelForTitle,
  showAttribute,
  EducationStatus,
} from '@/types/education';
import Vue, { PropType } from 'vue';
import { CardRowType } from '@/types/shared';
import { notEmpty } from '@/utilities/Utils';
export default Vue.extend({
  data() {
    return {
      CardRowType,
    };
  },
  props: {
    educationItems: { type: Array as PropType<Education[]> },
    index: { type: Number },
    type: { type: String },
    isAddDialog: { type: Boolean },
  },
  computed: {
    // country(): Country {
    //   return this.educationItem?.country ?? {};
    // },
    selectedAreas() {
      const areaCategories =
        this.educationItems[this.index].areas
          ?.map((area) => area.areaCategory)
          .filter(notEmpty)
          .map((category) => category.name) ?? [];

      const areas =
        this.educationItems[this.index].areas?.map((a) => a.name) ?? [];
      return [...[...new Set(areaCategories)], ...areas];
    },
    educationItem(): Education {
      return this.educationItems[this.index];
    },
    titleLabel(): string {
      return getLabelForTitle(this.educationItems[this.index]);
    },
    graduationYear(): string {
      const date = this.educationItems[this.index].graduationYear;
      if (this.educationItems[this.index].status === EducationStatus.LAUFEND) {
        return date ? `vsl. ${date}` : '';
      } else {
        return date?.toString() ?? '';
      }
    },
    cardTitle(): string {
      switch (this.type) {
        case 'school':
          return 'Schule';
        case 'education':
          return 'Gewünschte(s) Ausbildung / Studium';
        default:
          return 'Sonstige Ausbildung';
      }
    },
  },
  methods: {
    isVisible(row: string): boolean {
      return showAttribute(this.educationItems[this.index], row);
    },
  },
  components: {
    Row,
    UpdateEducationDialog,
  },
});
