export enum CardRowType {
  TEXT = 'text',
  CHIPS = 'chips',
  STATUS = 'status',
  DATE = 'date',
  MONTH = 'month',
  YEAR = 'year',
  AVATAR = 'avatar',
  LOGO = 'logo',
  IMAGE = 'image',
  LIST = 'list',
  BOOLEAN = 'boolean',
  DECIMAL = 'decimal',
  FLAGS = 'flags',
}
