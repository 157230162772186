<template lang="pug">
CardMobileDesktop(:isLoading="loading" :title="title")
  UpdateEducationDialog(slot="actionSlot" :educationItems="educationItems" :type='type' indexToUpdate="0"  :sectionTitle="title" :isAddDialog="educationItems.length === 0" v-if="!disableEdit")
  div.py-2.pt-3(slot="inputSlot")
    EmptyCardText(v-if='!educationItems ||educationItems.length === 0')
    div(v-else v-for='(entry, index) in educationItems' :key="index")
      CardEducationItem.mb-4(:educationItems="educationItems"  :type='type' :index="index" :sectionTitle="title" :isAddDialog='false' :disableEdit="disableEdit")
</template>

<script>
import CardEducationItem from './CardEducationItem';
import UpdateEducationDialog from './UpdateEducationDialog.vue';
import CardMobileDesktop from '@/components/cards/CardMobileDesktop';
import EmptyCardText from '@/components/cards/EmptyCardText';

export default {
  components: {
    CardEducationItem,
    UpdateEducationDialog,
    CardMobileDesktop,
    EmptyCardText,
  },
  props: ['educationItems', 'type', 'loading', 'title', 'disableEdit'],
};
</script>

<style lang="sass">
@import '@/assets/style/main'



// .status-chip
//   background-color: gray
//   color: white
//   border-radius: 7px
//   padding: 2px 10px 2px 10px
//   text-transform: uppercase
//   font-size: 10px
//   font-weight: 800
.color-gray
  color: rgba(0, 0, 0, 0.6)
</style>
