<template lang="pug">
div(v-if="conditionsItem")
      //- v-card-text.dialog-card-text(v-if="conditionsItem")
      v-row
        v-col(cols="12" md="6")
          SelectDialog(label="Anstellungsart" :items="jobTypeList"  v-model="conditionsItem.jobType" :multiple="true" :required="true" :chips="true" :rules="[v => (v && v.length > 0) || 'Pflichtfeld']")
          DatePickerDialog.mt-md-7.mt-6(slot="inputSlot" label="Beginn" v-model="conditionsItem.startDate" type="month" :required="true"   :min="currentDate" )

                
          SelectDialog.mt-md-7.mt-6(label="Arbeitszeit" :items='workingTimeList' v-model='conditionsItem.workingTime'  :multiple="true" :chips="true" :required="true" :rules="[v => (v && v.length > 0) || 'Pflichtfeld']")
          
          SelectDialog.mt-md-7.mt-6(label="Laufzeit" :items='durationList'  v-model='conditionsItem.duration'  :multiple="true" :chips="true" :required="true" :rules="[v => (v && v.length > 0) || 'Pflichtfeld']")
          LocationSelector.mt-md-7.mt-6(v-model="conditionsItem.city" label="Ort" :required="true")
        v-col.pl-md-4(cols="12" md="6")
          SelectAreaMenu(:areaItems="conditionsItem.areas" :required="true" @areaChanged="areaItemsChanged")
          
          SelectDialog.mt-md-7.mt-6(label="Level" :items='experienceLevels' v-model='conditionsItem.level'  :multiple="true" :chips="true" :required="true" :rules="[v => (v && v.length > 0) || 'Pflichtfeld']" )
          
          //- strong Gehaltwunsch
          SelectDialog.mt-md-7.mt-6(label="Jahresgehalt" :items='currencyItems' v-model='conditionsItem.desiredSalary' formating="currency"  :multiple="false"  :required="false")

          SelectDialog.mt-md-7.mt-6(label="Jahresurlaub" :items='vacationItems' v-model='conditionsItem.vacationDays' formating="vacation"  :multiple="false"  :required="false")

 
          SelectDialog.mt-6.mt-md-7(
              label="Benefits"
              :items='benefitsList' 
              :chips="true"
              v-model='conditionsItem.benefits'
              :multiple="true"
            )
</template>

<script>
import Vue from 'vue';

import { generateArrayRange } from '@/utilities/Utils';
import InputRow from '@/components/inputs/InputRow.vue';
import SelectAreaMenu from '@/components/dialogs/SelectAreaDialog.vue';
import SelectDialog from '@/components/dialogs/SelectDialog.vue';
import LocationSelector from '@/components/inputs/locationSelector.vue';
import { countries } from '@/assets/selections/countries';
import jobTypes from '@/assets/selections/jobTypes.json';
import benefits from '@/assets/selections/benefits.json';
import experienceLevels from '@/assets/selections/experienceLevels.json';
import workingTimes from '@/assets/selections/workingTimes.json';
import workingDurations from '@/assets/selections/workingDurations.json';
import DatePickerDialog from '@/components/dialogs/DatePickerDialog.vue';

export default Vue.extend({
  components: {
    SelectAreaMenu,
    SelectDialog,
    InputRow,
    LocationSelector,
    DatePickerDialog,
  },
  props: ['value'],
  data() {
    const vacationDaysTextField = this.value?.vacationDays ?? '-';
    return {
      experienceLevels: experienceLevels.lookingFor,
      dialogVisible: false,
      workingTimeMenu: false,
      jobTypeList: jobTypes.offering,
      workingTimeList: workingTimes,
      durationList: workingDurations,
      nationalities: countries,

      benefitsList: benefits,
      vacationDaysTextField,
      // currentDate: new Date().toISOString().slice(0, 10),
    };
  },
  computed: {
    vacationItems() {
      return generateArrayRange(4, 40, 1);
    },
    currencyItems() {
      return generateArrayRange(1000, 200000, 1000);
    },
    conditionsItem() {
      return this.value;
    },
    currentDate() {
      return new Date().toISOString();
    },
  },
  methods: {
    areaItemsChanged(areas) {
      this.conditionsItem.areas = areas;
    },
    vacationDaysChanges(days) {
      this.vacationDaysTextField = days > 3 ? days : '-';
    },
  },
});
</script>

<style lang="sass">


// @import "@/assets/style/main"
// .margin-2
//   @media only screen and (min-width: 960px)
//     margin-top: 2px

// .gray-caption
//   @include uppercase-gray-bold-text(12px)

// .status-chip-select
//   background-color: inherit !important
//   color: gray !important
//   border: solid gray 2px !important
//   border-radius: 7px !important
//   padding: 0px 10px 0px 10px !important
//   font-size: 10px !important
//   height: 20px !important
//   font-weight: 700 !important
//   text-transform: uppercase

// .status-chip-selected
//   color: white !important
//   background-color: gray !important
</style>
