<template lang="pug">
  CardMobileDesktop(:isLoading="isLoading" :title="title") 
    UpdateSkillDialog(:skillItems='skillItems' :type='type' slot='actionSlot' v-if="!disableEdit" :isAddDialog="skillItems.length == 0")
    div.py-4(slot="inputSlot")
      EmptyCardText(v-if='!skillItems || skillItems.length === 0')
      div(v-else v-for='(skill, index) in skillItems' :key="index")
        Row(v-if='skill.level' :title='skill.name' :type='type' :value='skill' :border="index > 0")
      
      
</template>

<script>
import CardMobileDesktop from '@/components/cards/CardMobileDesktop';
import Row from './CardSkillRow';
import EmptyCardText from '@/components/cards/EmptyCardText';
import UpdateSkillDialog from './UpdateSkillDialog.vue';
export default {
  components: { CardMobileDesktop, Row, UpdateSkillDialog, EmptyCardText },
  props: ['type', 'skillItems', 'title', 'disableEdit'],
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.business.loading;
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/style/main'
@import '@/assets/style/flag-icon.scss'
.skill-row
  margin: 0 !important

.no-padding
  padding: 0 !important

.no-vertical-padding
  padding: 0px 12px 0 12px !important

.level-caption
  @include uppercase-gray-bold-text(15px)

.ratingContainer
  max-height: 50px
</style>
