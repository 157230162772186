<template lang="pug">
    v-row
      v-col.hidden-sm-and-down(cols='12', md='4')
      v-col
        p.text-center.text-md-left {{text ? text: "Es gibt noch keine Informationen"}}
</template>

<script>
export default {
  props: ['text'],
};
</script>
