<template lang="pug">
v-dialog(v-model='dialogVisible' max-width="700"  :fullscreen='$vuetify.breakpoint.xs' :hide-overlay='$vuetify.breakpoint.xs'  scrollable @click:outside="cancel")
  template(v-slot:activator='{ on, attrs }')
    v-btn.ml-auto(icon v-bind='attrs' v-on='on')
      v-icon(color='accent' size='22px') {{ isAddDialog ? 'mdi-plus-circle-outline' : 'mdi-pencil' }}
  v-form( ref="otherRequirementsForm"  lazy-validation :key="formKey" v-model="valid")
    v-card.d-flex.flex-column(min-height="70vh")
      div
        v-toolbar(flat color="transparent")
          v-toolbar-title.text-h6.dialog-title.font-weight-bold
            | Weitere Anforderungen {{ isAddDialog ? 'hinzufügen' : 'bearbeiten' }}
          v-spacer
          v-btn(icon @click='cancel')
            v-icon mdi-close
        v-divider
      UpdateOtherRequirementsForm(v-model="elements" @newElementChanged="newElementChanged")
      //- v-spacer    
      v-divider
      v-card-actions.px-4
          v-btn(v-if='items.length > 0' icon @click='deleteOtherRequirements')
            v-icon(color='accent') mdi-delete-outline
          v-spacer
          v-btn(color='grey' text @click='cancel')
            | Abbrechen
          v-btn.white--text(color='accent darken-1' depressed @click='saveChanges' :disabled="!valid")
            | {{ isAddDialog ? 'hinzufügen' : 'speichern' }}
          
</template>

<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import UpdateOtherRequirementsForm from '@/components/business/lookingFor/other/UpdateOtherRequirementsForm.vue';

export default Vue.extend({
  components: { UpdateOtherRequirementsForm },
  props: ['items', 'isAddDialog'],
  data() {
    return {
      valid: true,
      formKey: Math.random(),
      dialogVisible: false,
      elements: [],
      newElement: '',
    };
  },
  created() {
    this.elements = cloneDeep(this.items);
  },
  methods: {
    cancel() {
      this.elements = cloneDeep(this.items);
      this.newElement = '';
      this.dismissDialog();
    },
    dismissDialog() {
      this.dialogVisible = false;
    },
    deleteOtherRequirements() {
      this.elements = [];
      this.saveChanges();
    },
    saveChanges() {
      if (this.newElement) {
        this.elements.push(this.newElement);
      }

      if (!this.$refs.otherRequirementsForm.validate()) {
        return;
      }

      this.$store
        .dispatch('business/partialUpdate', [
          {
            item: this.elements,
            key: 'otherRequirements',
          },
        ])
        .then(() => {
          this.dismissDialog();
        });
    },
    newElementChanged(newElement) {
      this.newElement = newElement;
    },
  },
});
</script>

<style lang="sass">
@import "@/assets/style/main"
</style>
