<template lang="pug">
v-dialog(v-model='dialogVisible'  max-width="700" :fullscreen='$vuetify.breakpoint.xs' :hide-overlay='$vuetify.breakpoint.xs'  scrollable @click:outside="cancel")
  template(v-slot:activator='{ on, attrs }')
    v-btn.ml-auto(icon v-bind='attrs' v-on='on')
      v-icon(color='accent' size='22px') {{ isAddDialog ? 'mdi-plus-circle-outline' : 'mdi-pencil' }}
  v-form( ref="experienceForm" :key="formKey" v-model="valid" lazy-validation)
    v-card.d-flex.flex-column(min-height="70vh")
      div
        v-toolbar(flat color="transparent")
          v-toolbar-title.text-h6.dialog-title.font-weight-bold
            | {{ sectionTitle }} {{ isAddDialog ? 'hinzufügen' : 'bearbeiten' }}
          v-spacer
          v-btn(icon @click='cancel')
            v-icon mdi-close
        v-divider
      UpdateExperienceForm(v-model="updatedExperienceItems" :indexToUpdate="indexToUpdate" :type="type" :valid="valid" :formRef="$refs.experienceForm")
      //- v-spacer    
      v-divider
      v-card-actions.px-4
          v-btn(v-if='!isAddDialog' icon @click='deleteExperience')
            v-icon(color='accent') mdi-delete-outline
          v-spacer
          v-btn(color='grey' text @click='cancel')
            | Abbrechen
          v-btn.white--text(color='accent darken-1' depressed :disabled="!valid" @click='setExperiences')
            | {{ isAddDialog ? 'hinzufügen' : 'speichern' }}
</template>

<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import UpdateExperienceForm from '@/components/business/lookingFor/experiences/UpdateExperienceForm.vue';

export default Vue.extend({
  components: { UpdateExperienceForm },
  props: [
    'type',
    'experienceItems',
    'indexToUpdate',
    'sectionTitle',
    'isAddDialog',
  ],
  data() {
    return {
      formKey: Math.random(),
      valid: false,
      dialogVisible: false,
      updatedExperienceItems: cloneDeep(this.experienceItems),
    };
  },
  computed: {},
  methods: {
    cancel() {
      const previousItem = this.experienceItems[this.indexToUpdate];
      this.updatedExperienceItems[this.indexToUpdate] = previousItem ?? {
        activity: '',
        countries: [],
        timeEnd: '',
        timeStart: '',
        level: [],
        title: '',
        type: this.type,
        areas: [],
      };
      this.dismissDialog();
    },
    dismissDialog() {
      this.dialogVisible = false;
      this.formKey++;
    },
    setExperiences() {
      if (!this.$refs.experienceForm.validate()) {
        return;
      }

      const updatedItems = cloneDeep(this.updatedExperienceItems);
      this.dispatchUpdate(updatedItems);
    },
    deleteExperience() {
      const updatedItems = cloneDeep(this.updatedExperienceItems);
      updatedItems.pop(this.indexToUpdate);
      this.dispatchUpdate(updatedItems);
    },
    dispatchUpdate(updatedItems) {
      this.$store
        .dispatch(`business/partialUpdate`, [
          {
            item: updatedItems,
            key: this.type,
          },
        ])
        .then(() => {
          this.dismissDialog();
        });
    },
  },
});
</script>

<style lang="sass">
@import "@/assets/style/main"
.gray-caption
  @include uppercase-gray-bold-text(12px)

.status-chip-select
  background-color: inherit !important
  color: gray !important
  border: solid gray 2px !important
  border-radius: 7px !important
  padding: 0px 10px 0px 10px !important
  font-size: 10px !important
  height: 20px !important
  font-weight: 700 !important
  text-transform: uppercase

.status-chip-selected
  color: white !important
  background-color: gray !important
</style>
