<template lang="pug">
.offerings
  .elements(v-if="!isLoading && currentPosition")
    cardEducation(type='school' title='Gewünschte Schule' :educationItems="currentPosition.school")
    cardEducation(type='education' title='Gewünschte(s) Ausbildung / Studium' :educationItems="currentPosition.education")
    cardExperience(type='practicalExperience' title='Gewünschte Praxiserfahrung' :experienceItems="currentPosition.practicalExperience")
    cardExperience(:type='ExperienceType.OTHER_EXPERIENCE' title='Gewünschte Auslandserfahrung' :experienceItems="currentPosition.otherExperience")
    cardSkill(:type='SkillType.LANGUAGES' title='Gewünschte Sprachen' :skillItems="orderedLanguages")
    cardSkill(:type='SkillType.SOFTWARE' title='Gewünschte Software' :skillItems="orderedSoftwareSkills")
    cardOtherRequirements(title='Weitere Anforderungen' :items="currentPosition.otherRequirements")
  EmptyCardPosition(v-else-if="!isLoading && !currentPosition" text="Noch keine Stelle hinzugefügt")
</template>

<script>
import cardEducation from '@/components/business/lookingFor/educations/CardEducation.vue';
import cardExperience from '@/components/business/lookingFor/experiences/CardExperience.vue';
import cardSkill from '@/components/business/lookingFor/skills/CardSkill.vue';
import cardOtherRequirements from '@/components/business/lookingFor/other/OtherRequirements.vue';
import { UserType } from '@/types/user';
import { SkillType } from '@/types/skill';
import { ExperienceType } from '@/types/experience';
import EmptyCardPosition from '@/components/cards/EmptyCardPosition';
import { orderSkillItems } from '@/utilities/Utils';
export default {
  components: {
    cardExperience,
    cardSkill,
    cardEducation,
    cardOtherRequirements,
    EmptyCardPosition,
  },
  data() {
    return {
      ExperienceType,
      UserType,
      SkillType,
    };
  },
  computed: {
    currentPosition() {
      return this.$store.state.business.positions.find(
        (position) =>
          position.id === this.$store.state.business.selectedPositionId
      );
    },
    isLoading() {
      return this.$store.state.business.loading;
    },
    orderedLanguages: function() {
      return orderSkillItems(this.currentPosition.languages);
    },
    orderedSoftwareSkills: function() {
      return orderSkillItems(this.currentPosition.softwareSkills);
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.$store.dispatch('business/fetchPositions');
  },
};
</script>

<style lang="sass">
@import '@/assets/style/main'
.offerings
  margin-bottom: 60px
.margin-top
  margin-top: 20px
.right-col
  padding-left: 20px
</style>
