<template lang="pug">
v-dialog(v-model='dialogVisible' max-width="700" :fullscreen='$vuetify.breakpoint.xs' :hide-overlay='$vuetify.breakpoint.xs' scrollable @click:outside="cancel")
  template(v-slot:activator='{ on, attrs }')
    v-btn.ml-auto(icon v-bind='attrs' v-on='on')
      v-icon(color='accent' size='22px') {{ isAddDialog ? 'mdi-plus-circle-outline' : 'mdi-pencil' }}
  v-form( ref="educationForm" :key="formKey" lazy-validation v-model="valid")
    v-card.d-flex.flex-column(min-height="70vh")
      div
        v-toolbar(flat color="transparent")
          v-toolbar-title.text-h6.dialog-title.font-weight-bold
            | {{ sectionTitle }} {{ isAddDialog ? 'hinzufügen' : 'bearbeiten' }}
          v-spacer
          v-btn(icon @click='cancel')
            v-icon mdi-close
        v-divider
      UpdateEducationForm(v-model="updatedEducationItems" :indexToUpdate="indexToUpdate" :type="type" :valid="valid")
      v-divider
      v-card-actions.px-4
        v-btn(v-if='!isAddDialog && type !== EducationType.SCHOOL' icon @click='deleteEducation')
          v-icon(color='accent') mdi-delete-outline
        v-spacer
        v-btn(color='grey' text @click='cancel')
          | Abbrechen
        v-btn.white--text(color='accent darken-1' depressed @click='setEducations' :disabled="!valid")
          | {{ isAddDialog ? 'hinzufügen' : 'speichern' }}
</template>

<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import UpdateEducationForm from '@/components/business/lookingFor/educations/UpdateEducationForm.vue';
import { EducationType } from '@/types/education';
export default Vue.extend({
  components: { UpdateEducationForm },
  props: [
    'type',
    'educationItems',
    'indexToUpdate',
    'sectionTitle',
    'isAddDialog',
  ],
  data() {
    return {
      formKey: Math.random(),
      valid: false,
      dialogVisible: false,
      EducationType,
      updatedEducationItems: cloneDeep(this.educationItems),
    };
  },
  computed: {},
  methods: {
    cancel() {
      const previousItem = this.educationItems[this.indexToUpdate];
      this.updatedEducationItems[this.indexToUpdate] = previousItem ?? {
        level: [],
        graduation: [],
        averageGrade: 0,
        status: undefined,
        graduationYear: undefined,
        type: this.type,
        areas: [],
      };
      this.dismissDialog();
    },
    dismissDialog() {
      this.dialogVisible = false;
      this.formKey++;
    },
    setEducations() {
      if (!this.$refs.educationForm.validate()) {
        return;
      }

      const updatedItems = cloneDeep(this.updatedEducationItems);
      this.dispatchUpdate(updatedItems);
    },
    deleteEducation() {
      const updatedItems = cloneDeep(this.educationItems);
      updatedItems.pop(this.indexToUpdate);
      this.dispatchUpdate(updatedItems);
    },
    dispatchUpdate(updatedItems) {
      this.$store
        .dispatch('business/partialUpdate', [
          {
            item: updatedItems,
            key: this.type,
          },
        ])
        .then(() => {
          this.dismissDialog();
        });
    },
  },
});
</script>

<style lang="sass">
@import "@/assets/style/main"
</style>
